<template>
  <section>

    <v-sheet
      color="white"
      elevation="4"
      outlined
      rounded
      width="352px"
      class="ma-5 pa-5 rounded-lg"
    >
      <v-form id="emailVerificationForm" ref="emailVerificationForm" :model="form" :rules="rules" @submit.native.prevent="checkEmail">
        <v-text-field
          v-model="form.email"
          ref="email"
          :label="$_strings.welcomePage.EMAIL"
          clearable
          dense
          :rules="rules.email"
          @focus="clearRulesEmail"
          @blur="blurRulesEmail"
          class="mb-2"
          :hint="emailHint"
          persistent-hint
        ></v-text-field>
        <phone-number-format
          v-model="form.phoneNo"
          :label="$_strings.welcomePage.PHONE_NUMBER"
          clearable
          dense
          bindClass="body-2 mb-5 mt-5"
          persistent-hint
          :required="true"
        ></phone-number-format>
        <v-btn rounded large class="background-denim white--text font-weight-bold body-2 w-100" type="submit">
          {{$_strings.welcomePage.ACTIVATE_ACCOUNT}}
        </v-btn>
      </v-form>
    </v-sheet>
    <!-- <v-footer class="d-flex justify-end">
      <p>Copyright Fastlog 2020 - <a target="_blank" href="http://">fastlog.id</a> </p>
    </v-footer> -->
  </section>
</template>

<script>
export default {
  name: 'email-verification',
  data() {
    return {
      form: {
        email: '',
        phoneNo: '',
      },
      emailHint: '',
      rules: {
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED('Email harus diisi');
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
      },
    };
  },
  methods: {
    refactorPhoneNumber(phoneNumber) {
      return phoneNumber.replaceAll(/\s|-/g, '');
    },
    checkEmail() {
      const valid = this.$refs.emailVerificationForm.validate();
      if (valid) {
        this.$root.$loading.show();
        this.$_services.account.verifyEmail(this.form)
          .then((result) => {
            this.$router.push({ path: '/welcome/activation', query: { email: this.form.email, phoneNo: this.form.phoneNo } });
          })
          .finally((e) => {
            this.$root.$loading.hide();
          });
      }
    },
    clearRulesEmail() {
      this.emailHint = this.$_strings.welcomePage.EMAIL_HINT;
      this.rules.email = [];
    },
    blurRulesEmail() {
      this.emailHint = '';
      const rulesEmail = [
        (v) => {
          if (v) {
            return true;
          }
          return this.$_strings.messages.validation.REQUIRED('Email');
        },
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (pattern.test(value)) {
            return pattern.test(value);
          }
          return this.$_strings.messages.validation.EMAIL;
        },
      ];
      this.rules.email = rulesEmail;
    },
  },
};
</script>

<style scoped lang="scss">
.v-footer {
  background: none;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
